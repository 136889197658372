import { useState, useEffect } from "react";
import { Button, Card, Container, Divider, Grid, GridColumn, Icon, Label } from 'semantic-ui-react';
import { fetchAplicaciones, isSuccess } from "./api";
import './App.css';


function App() {
  const [apps, setApps] = useState([])
  useEffect(() => {
    async function init() {
      try {
        const res = await fetchAplicaciones()
        if (isSuccess(res))  {
          setApps(res.data)
        }
      } catch (e) {
        console.log(e)
      }
    }

    init()
  }, [])

  return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height:'100vh'}}>
      <Container>
        <div className="cs-text-center cs-mb-5">
          <img src="logo512.png" width={200} height={200} alt="logo"/>
          <div>
            <img src="titulo.png" alt="comandapp"/>
          </div>
        </div>
        <Divider ></Divider>
        <Grid columns={4} padded stackable centered>
          {apps.map(v => (
            <GridColumn stretched key={v._id}>
              <Aplicacion { ...v } />
            </GridColumn>
          ))}
        </Grid>
      </Container>
      <footer className="cs-text-center">
        <div>
          <Icon name="mail" /> develop@c28.cl
        </div>
        <div><Icon name="copyright"/> Comandapp {new Date().getFullYear()}</div>
      </footer>
    </div>
  );
}

const Aplicacion = ({ titulo, descripcion, url, target, version, icon }) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header textAlign="center">{titulo}</Card.Header>
        <Card.Description>{descripcion}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        {icon && <div style={{ marginBottom: 8 }}>
          <Label><Icon name={icon} /> {icon}</Label>
        </div>}
        <a href={url}>
          <Button primary basic icon fluid labelPosition="left">
            <Icon name="download" />
            {target ? 'Abrir' : `Descargar ${version}`}
          </Button>
        </a>
      </Card.Content>
    </Card>
  )
}

export default App;
