const host = process.env.NODE_ENV === 'production' ? 'https://api.comandapp.cl' : 'http://192.168.0.11:8000'

export function get(path, headers) {
  return request(path, 'GET', undefined, headers)
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function put(path, body) {
  return request(path, 'PUT', body)
}

export function del(path, body) {
  return request(path, 'DELETE', body)
}

function request(path, method, body, headers) {
  return fetch(host + path, {
    method,
    headers: {
      ...headers
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(success)
}

function success(response) {
  return response.json()
}